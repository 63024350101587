<template>
  <div class="roleEmpowerment">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>角色赋权</el-breadcrumb-item>
    </el-breadcrumb>

    <el-collapse
      accordion
      v-for="(item, index) in powerList"
      :key="item.columnId"
    >
      <el-collapse-item :title="item.columnName" :name="index">
        <div>
          <el-collapse
            accordion
            v-for="(item1, index1) in item.manageColumnList"
            :key="item1.columnId"
          >
            <el-collapse-item :title="item1.columnName">
              <div>
                <el-collapse
                  accordion
                  v-for="(item2, index2) in item1.manageColumnList"
                  :key="item2.columnId"
                >

                  <el-collapse-item :title="item2.columnName">
                    <!-- <el-checkbox-group v-model="checkedPower" @change="onCheckedPower">
                      @change="onPowerChange(item2, item3)"
                    <el-checkbox
                      
                      :checked="item3.isSelect"
                      v-for="item3 in item2.managePowerMapList"
                      :key="item3.powerId"
                     >{{ item3.power.powerName }}</el-checkbox>
                      </el-checkbox-group> -->
                    <template>
                    <!-- <el-checkbox  @change="onCheckedAll(item2)">全选</el-checkbox> -->
                    <el-checkbox
                      @change="onPowerChange(item2, item3)"
                      :checked="item3.isSelect"
                      v-for="item3 in item2.managePowerMapList"
                      :key="item3.powerId"
                     >{{ item3.power.powerName }}</el-checkbox>
                    </template>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import { columnList } from "../../api/column";
import { roleEmpowermentList, addPowerByRole } from "../../api/power";
export default {
  name: "roleEmpowerment",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      powerList: [],
      activeNames: [],
      checkedAll:false,
    };
  },
  created() {
    this.getroleEmpowermentList();
  },
  methods: {
    onCheckedPower(value){
      console.log(value,'value')
      console.log(this.checkedPower)
    },
    async onPowerChange(item2, item3) {
      const { data } = await addPowerByRole({
        manageColumnId: item2.columnId,
        manageRoleId: Number(this.id),
        managePowerId: item3.power.powerId,
      });
      if (data.code === 0) {
        this.$message({
          message: "赋权成功",
          type: "success",
        });
      } else {
        this.$message.error("赋权失败请稍后重试");
      }
    },
    async getroleEmpowermentList() {
      const { data } = await roleEmpowermentList({
        roleId: Number(this.id),
      });
      let that = this;
      data.data.forEach((item, index) => {
        that.activeNames.push(index + "");
        item.manageColumnList.forEach((item2, index2) => {
          that.activeNames.push(`${index}-${index2}`);
          item2.manageColumnList.forEach((item3, index3) => {
            that.activeNames.push(`${index}-${index2}-${index3}`);
          });
        });
      });
      this.powerList = data.data;
    },
  },
};
</script>
<style lang="less" scoped>
.roleEmpowerment {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  /deep/ .el-collapse {
    margin-top: 50px;
    .el-collapse-item {
      .el-collapse-item__wrap {
        border-bottom: 0;
        .el-collapse-item__content {
          .el-collapse {
            margin-top: 0;
            .el-collapse-item {
              .el-collapse-item__header {
                margin-left: 40px;
              }
              .el-collapse-item__content {
                .el-collapse {
                  .el-collapse-item {
                    .el-collapse-item__wrap {
                      margin-left: 120px;
                    }
                    .el-collapse-item__header {
                      margin-left: 80px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>